<template>
    <div class="company-dialog">
      <b-modal id="modalFilter" :title="$t('Búsqueda Avanzada')" modal-class="custom-dialog dialog-600" :ok-title="$t('Filtrar')" ok-variant="warning" 
        ok-only no-close-on-esc no-close-on-backdrop centered @close="close" @ok="ok" :ok-disabled="loading">
        <div class="mt-0">
          <form-render :form.sync="form" :fields="fields" @send="onAccept"
            ref="formRenderFilter"
            containerButtonsClass="col-sm-12 col-lg-4 container-button">
          </form-render>
        </div>
      </b-modal>
    </div>
  </template>
<script>
export default {
  name:'modalMaterial',
  props: ['filter', 'countries', 'executives'],
  data () {
    return {
      form: {},
      fields: [],
      loading: true,
      showRequest: false,
      executive: [],
      keyFormRender: 0,
      typeOrganization: [
        {id: 1, text: 'Marketplace'},
        {id: 2, text: 'E-Commerce'},
        {id: 3, text: 'Seller'}
      ]
    }
  },
  watch: {
    filter () {
      this.form = {
        ...this.filter
      }
      this.setInitialData()
      this.loading = false
      this.keyFormRender++
    },
    countries () { 
      this.setSelectOptions('country_id', { options: this.countries })
      this.loading = false
    },
    executives () { 
      this.setSelectOptions('executives', { options: this.executive })
      this.loading = false
    }
  },
  mounted () {
    this.getAllInitialData()
    
  },
  methods: {
    getAllInitialData () {
      // Inicialización del endpoint de ejecutivos ya esta la implementación de Vuex pero el endpoint no esta creado aun
      /* this.$store.dispatch('fetchService', { name: 'getExecutives'}) */
      this.setInitialData()
    },
    setInitialData() {
      this.fields = [ 
        {fieldType: 'FieldInput', label: 'Nombre', name: 'name', clearable: true, containerClass: 'col-md-12 container-info'},
        {fieldType: 'FieldInput', label: 'Código', name: 'code', clearable: true, containerClass: 'col-md-12 container-info'},
        // Comentado ya que falta la refactorización del endpoint para poder filtrar por cod_name
        /* {fieldType: 'FieldInput', label: 'Nombre/código', name: 'cod_name', clearable: true, containerClass: 'col-md-12 container-info'}, */
        {fieldType: 'FieldSelect', name: 'organization_type', label: 'Tipo', containerClass: 'col-md-12 container-info', options: this.typeOrganization},
        {fieldType: 'FieldSelect', label: 'País', name: 'country_id', options: this.countries, clearable: true, containerClass: 'col-md-12 container-info'}  
        // Comentado ya que falta la refactorización del endpoint para poder filtrar por star/end(Fecha de creación) por otra parte no hay un endpoint que llene el selector de ejecutivo comercial  
        /*  {fieldType: 'FieldSelect', name: 'executives', label: 'Ejecutivo comercial', options: this.executive, containerClass: 'col-md-12 container-info', dependency: 'country_id'},   
        {fieldType: 'FieldDatepicker', name: 'creation_date', label: 'Fecha de creación', containerClass: 'col-sm-12 col-md-12 container-info', range: true} */
      ]
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderFilter.checkForm()
    },
    close () {
      this.$emit('update:filter', {})
    },
    onAccept (data) {
      this.loading = true
      const form = {
        cod_name: data.cod_name ? data.cod_name : '',
        name: data.name ? data.name : '',
        code: data.code ? data.code : '',
        organization_type: data.organization_type ? data.organization_type : '',
        country_id: data.country_id ? data.country_id : '',
        executives: data.executives ? data.executives : '',
        creation_date: data.creation_date ? data.creation_date : ''
      }
      this.$emit('send', { form, original: data})
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    changeCountry (name, value) {
      if (!value?.id) {
        return null
      } else {
        // Filtro de executives por país
        this.executive = this.executives.filter(executive => {
          return executive.country_code.toLowerCase() === value.code.toLowerCase()
        })
        this.setSelectOptions('executives', { options: this.executive })
      }
    }
      
  }
 
}
</script>

<style>
.modal-body{
    padding: 2.8rem 4.4rem !important;
}
h5 {
    font-size: 1.5rem;
    font-weight: 600;
}
</style>  